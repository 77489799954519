import { i18n } from '@/plugins/i18n'

export const StaticSelectOpts = {
  userStatusOptions: [
    { value: 'ACTIVE', text: i18n.t('user.status.active') },
    { value: 'INACTIVE', text: i18n.t('user.status.inactive') }
  ],

  userStatusOptionsFilter: [
    { value: 'ACTIVE', text: i18n.t('user.status.active') },
    { value: 'INACTIVE', text: i18n.t('user.status.inactive') },
    { value: 'DELETED', text: i18n.t('user.status.deleted') }
  ],
  userRoleOptions: [
    { value: 'ROLE_DATA_COLLECTOR', text: i18n.t('user.roles.role_data_collector') },
    { value: 'ROLE_TEAM_MEMBER', text: i18n.t('user.roles.role_team_member') },
    { value: 'ROLE_ADMIN', text: i18n.t('user.roles.role_admin') },
    { value: 'ROLE_DATA_SCIENTIST', text: i18n.t('user.roles.role_data_scientist') },
    { value: 'ROLE_DATA_RECIPIENT', text: i18n.t('user.roles.role_data_recipient') }
  ]
}
