<template>
  <b-container fluid="xxl">
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('user.link')" />
      <GoldflamTable
        :new-item="newUserObject"
        :all-items="filteredUsers"
        :edit-function="editUserFunction"
        :delete-function="deleteUser"
        :disable-crud-functions="disableCrudFunctions"
        :table-fields="tableFields"
        stacked="md"
      >
        <template v-slot:filters>
          <b-col md="4" sm="5">
            <b-select id="userRoleFilter" v-model="filter.role" :options="roleOptions">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-roles') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
          <b-col lg="2" md="3" sm="4">
            <b-select id="userStatusFilter" v-model="filter.status" :options="statusOptionsFilter">
              <template #first>
                <b-form-select-option :value="null">{{ $t('general.all-status') }}</b-form-select-option>
              </template>
            </b-select>
          </b-col>
        </template>
      </GoldflamTable>
      <b-modal
        id="editModal"
        v-model="modalControl.edit.show"
        size="lg"
        centered
        :title="$t('user.editUser')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveUser(editUser)"
        :ok-disabled="$v.$invalid"
        @hidden="resetEditModal"
      >
        <b-form>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editName"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.name')"
          >
            <b-form-input
              id="editName"
              type="text"
              v-model.trim="$v.editUser.fullName.$model"
              :state="validateFormInput('fullName')"
            />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editUsername"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.username')"
            :invalid-feedback="$t('user.invalidFeedback-msg-username')"
          >
            <b-form-input
              id="editUsername"
              type="text"
              v-model="$v.editUser.username.$model"
              :state="validateFormInput('username')"
            />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editUserMail"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.e-mail')"
            :invalid-feedback="$t('user.invalidFeedback-msg-email')"
          >
            <b-form-input
              id="editUserMail"
              type="text"
              v-model="$v.editUser.email.$model"
              :state="validateFormInput('email')"
            />
          </b-form-group>

          <b-form-group
            class="mt-4 mb-2"
            label-for="editUserRole"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.role')"
          >
            <b-input-group>
              <b-form-select id="editUserRole" type="text" v-model="editUser.role" :options="roleOptions" />
              <b-input-group-append>
                <b-button href="https://gitlab.com/kinsecta/kinsecta-web-app/-/wikis/Benutzerrollen" target="_blank">
                  <b-icon icon="question-circle" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="editUserStatus"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('general.status')"
          >
            <b-form-select id="editUserStatus" type="text" v-model="editUser.status" :options="statusOptions" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import GoldflamTable from '@/components/GoldflamTable'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { GoldflamEmail, GoldflamUsername } from '@/util/VuelidateValidators'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'
import { cloneDeep as _cloneDeep } from 'lodash'

export default {
  name: 'ManageUsers',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { GoldflamTable },

  data() {
    return {
      objectModels: {
        user: {
          fullName: '',
          username: '',
          email: '',
          role: '',
          status: ''
        }
      },
      editUser: {},
      allUsers: [],
      tableFields: [
        { key: 'id', label: this.$t('general.id'), sortable: true, width: '50px' },
        { key: 'fullName', label: this.$t('profile.name'), sortable: true },
        { key: 'username', label: this.$t('general.username'), sortable: true },
        { key: 'email', label: this.$t('general.e-mail'), sortable: true },
        { key: 'role', label: this.$t('general.role'), sortable: true, formatter: 'formatRole' },
        { key: 'status', label: this.$t('general.status'), sortable: true, formatter: 'formatActivityStatus' },
        { key: 'crudActions', label: this.$t('general.table.actions'), width: '80px' }
      ],
      roleOptions: StaticSelectOpts.userRoleOptions,
      userList: [],
      statusOptions: StaticSelectOpts.userStatusOptions,
      statusOptionsFilter: StaticSelectOpts.userStatusOptionsFilter,
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      modalControl: {
        edit: {
          show: false
        },
        delete: {
          show: false
        }
      },
      filter: {
        role: null,
        status: 'ACTIVE'
      }
    }
  },
  validations: {
    editUser: {
      fullName: {
        required
      },
      username: {
        required,
        GoldflamUsername
      },
      email: {
        required,
        GoldflamEmail
      },
      role: {
        required
      },
      status: {
        required
      }
    }
  },
  created() {
    this.editUser = _cloneDeep(this.objectModels.user)
  },
  mounted() {
    this.getAllUsers()
  },
  computed: {
    currentUser() {
      return this.$store.getters.getCurrentUser
    },
    filteredUsers() {
      let users = this.userList
      if (this.filter.status !== null && this.filter.status !== '') {
        users = users.filter(u => {
          return u.status === this.filter.status
        })
      }
      if (this.filter.role !== null && this.filter.role !== '') {
        users = users.filter(u => {
          return u.role === this.filter.role
        })
      }
      return users
    },
    newUserObject() {
      let newUser = _cloneDeep(this.objectModels.user)
      newUser.role = 'USER'
      newUser.status = 'ACTIVE'
      return newUser
    }
  },
  methods: {
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editUser[prop]
      return $dirty ? !$invalid : null
    },
    getAllUsers() {
      let self = this
      this.getRequest(
        '/users',
        new RequestConfig().onSuccess(res => {
          self.userList = res.data
        })
      )
    },
    saveUser(user) {
      let url = '/users'
      let method = 'post'
      if (user.id) {
        url += `/${user.id}`
        method = 'put'
      }
      let self = this
      this.apiRequest(
        method,
        url,
        user,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('user.user-success-msg'))
          this.getAllUsers()
        })
      )
    },
    editUserFunction(user) {
      this.editUser = _cloneDeep(user)
      this.modalControl.edit.show = true
    },
    deleteUser(user) {
      let self = this
      const id = user.id
      this.deleteRequest(
        `/users/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(this.$t('user.delete-success-msg'))
          this.getAllUsers()
        })
      )
    },
    disableCrudFunctions(user) {
      return user.status === 'DELETED'
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.editUser = _cloneDeep(this.objectModels.user)
      this.$v.$reset()
    }
  }
}
</script>
